<template>
  <div class="invitation">
    <div class="go-back-action" @click="router.go(-1)">
      <i class="icon">arrow_back</i>
      Back
    </div>
    <div class="abs">Invite your colleagues to take CIMM assessment</div>
    <div class="aiv">
      <img class="pgd" src="images/3bd4.png" />
      <div class="tvt">
        <template v-for="(email, index) in inputs.emails" :key="index">
          <div class="wrapper-form">
            <input-element
              :class="['input-field']"
              label="Working Email Address"
              v-model="inputs.emails[index]"
              :error="inputErrors.emails[index] ? 'Invalid email' : ''"
            />
            <i
              v-if="index !== 0"
              class="icon cancel"
              @click="removeInvite(index)"
              >cancel_outline</i
            >
          </div>
        </template>
        <div
          :class="[
            'wrapper-action-invite',
            {
              disabled: disabledAddEmail,
            },
          ]"
          @click="handleAddInvite"
        >
          <i class="icon">add_circle_outline</i>
          <div class="poj">Add more people</div>
        </div>
      </div>
    </div>
    <div class="uio">
      <button-element :disabled="sending" @click="send">
        Invite
        <progress-element class="poj" :active="sending" />
      </button-element>
    </div>
    <flash-message-component ref="successDialog" type="success">
      An invitation has been sent to your colleagues. Thank you for your
      participation.
    </flash-message-component>
    <flash-message-component ref="errorDialog" type="error">
      <template v-if="errorCode === 'user-not-found'"
        >Couldn't find your account with that email.</template
      >
      <template v-if="errorCode === 'unknown'"
        >Oops! Something went wrong. Please try again later.</template
      >
    </flash-message-component>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import FlashMessage from "@/components/flash-message/flash-message.vue";
import store from "@/store";
import { GET_USER, SEND_INVITATIONS } from "@/store/action-types";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    "flash-message-component": FlashMessage,
  },

  async beforeRouteEnter(to, from, next) {
    let user = await store.dispatch(GET_USER);
    if (!user) {
      next({ name: "login" });
      return;
    }
    next();
  },

  setup() {
    let store = useStore();
    let router = useRouter();
    const dataLayer = window.dataLayer;
    let sending = ref(false);
    let successDialog = ref(null);
    let errorDialog = ref(null);
    let errorCode = ref("");
    let inputs = ref({ emails: [""] });
    let inputErrors = ref({ emails: [] });
    let disabledAddEmail = computed(() => inputs.value.emails.length === 5);
    let validate = () => {
      inputErrors.value.emails = inputs.value.emails.map(
        (email) => !/\S+@\S+\.\S+/.test(email)
      );
      if (inputErrors.value.emails.some(Boolean)) return null;
      return inputs.value.emails;
    };

    let send = async () => {
      let emails = validate();
      if (!emails) return;

      try {
        sending.value = true;
        await store.dispatch(SEND_INVITATIONS, { emails });
        successDialog.value.show();
        for (let i = 0; i < emails.length; i++) {
          dataLayer.push({
            event: "email_invitation",
          });
        }
      } catch (error) {
        switch (error.code) {
          case "auth/invalid-email":
          case "auth/user-not-found":
            errorCode.value = "user-not-found";
            break;
          default:
            errorCode.value = "unknown";
        }
        errorDialog.value.show();
      } finally {
        sending.value = false;
      }
    };
    const removeInvite = (index) => {
      inputs.value.emails.splice(index, 1);
    };

    const handleAddInvite = () => {
      if (inputs.value.emails.length < 5) {
        inputs.value.emails.push("");
      }
    };

    return {
      handleAddInvite,
      sending,
      successDialog,
      errorDialog,
      errorCode,
      inputs,
      inputErrors,
      send,
      disabledAddEmail,
      removeInvite,
      router,
    };
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:map';
@use '@/styles/variables';
.go-back-action {
  display: flex;
  align-items: center;
  font-size: map.get(variables.$font-sizes, "sm");

  .icon {
    margin-right: 8px;
  }
}

.abs {
  margin-top: 16px;
  font-weight: map.get(variables.$font-weights, "bold");
  font-size: map.get(variables.$font-sizes, "lg");
  line-height: 1.5;
}

.aiv {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 24px;
  background-color: white;
  border-radius: 4px;
}

.pgd {
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
}

.tvt {
  margin-top: 24px;
}

.input-field {
  &:not(:first-child) {
    margin-top: 24px;
  }
}
.wrapper-form {
  display: flex;
  align-items: center;
  width: 100%;

  .input {
    width: 100%;
    margin: 6px;

    &:first-child {
      width: calc(100% - 20px);
    }
  }

  .icon {
    width: 20px;
    cursor: pointer;
  }
}

.wrapper-action-invite {
  display: inline-flex;
  align-items: center;
  margin-top: 24px;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.poj {
  margin-left: 16px;
}

.uio {
  margin-top: 24px;
  text-align: center;
}

@media (min-width: map.get(variables.$breakpoints, "lg")) {
  .abs {
    text-align: center;
  }

  .aiv {
    flex-direction: row;
    align-items: flex-start;
    margin-top: 32px;
    padding: 32px;
  }

  .tvt {
    margin: 0 0 0 32px;
    width: 100%;
  }

  .input-field {
    &:not(:first-child) {
      margin-top: 32px;
    }
  }

  .tgk {
    margin-top: 32px;
  }

  .uio {
    margin-top: 32px;
  }
}
</style>
